import * as React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';
import { delay1, fadeIn, fadeInUp, Section, inViewConfig } from '../../templates/CommonLayout';

import Title from '../Title';

const Gallery = () => {
  const [ref4, inView4] = useInView(inViewConfig);

  return (
    <div ref={ref4}>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'space-evenly',
          marginBottom: '1.45rem',
          minHeight: '200px',
        }}
        className={inView4 ? fadeIn : ''}
      >
        <StaticImage
          src="../../assets/images/concept/01.png"
          alt="和を慈しむこだわりの茶器や花器など、お食事とともに目でも楽しんで頂けます。"
        />
        <StaticImage
          src="../../assets/images/concept/02.png"
          style={{ margin: '0 1em' }}
          alt="ジャパニーズ・ウィスキーや焼酎など日本独自の食後酒と、コニャック、カルヴァドス、グラッパなど欧州食後酒も各種取り揃えております。"
        />
        <StaticImage src="../../assets/images/concept/03.png" alt="専用出入り口を備えた、完全個室もご利用頂けます。" />
      </div>
    </div>
  );
};

const Concept = () => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;

  const [ref1, inView1] = useInView(inViewConfig);
  const [ref2, inView2] = useInView(inViewConfig);
  const [ref3, inView3] = useInView(inViewConfig);

  return (
    <Section id="concept">
      <div
        style={{
          display: 'flex',
          flexDirection: isSmallViewer ? 'column' : 'row-reverse',
          justifyContent: 'flex-end',
        }}
      >
        <div ref={ref1} style={{ flex: 0.7, alignSelf: 'center', marginBottom: isSmallViewer ? '4em' : '1em' }}>
          <StaticImage
            src="../../assets/images/concept/main.png"
            objectPosition="58% center"
            style={{ minHeight: '400px' }}
            alt="神保町 五木田 外観"
            className={inView1 ? fadeIn : ''}
          />
        </div>
        <article style={{ flex: 0.3, marginRight: isSmallViewer ? 0 : '6em' }}>
          <div ref={ref2}>
            <Title label="Concept" ruby="想い" align="left" className={inView2 ? fadeInUp : ''} />
          </div>

          <div ref={ref3}>
            <p
              style={{
                marginTop: isSmallViewer ? '0em' : '4em',
                marginBottom: '2em',
                color: 'var(--font-dark)',
                padding: isSmallViewer ? '2em' : 0,
              }}
              className={inView3 ? fadeInUp + delay1 : ''}
            >
              「神保町 五木田」は、フランス料理の技術を活かしつつ、焼鳥にも特化した炭焼き/薪焼きのお店です。
              <br />
              日仏のレストランで研鑽を積んできたシェフが奏でる香り高い料理を、その味をさらに広げるソースとともにお楽しみ下さい。
              <br />
              料理を引き立たせる、シェフ自ら厳選したワインも300～400本と豊富に取り揃えております。
              <br />
              桜の一枚板のカウンターをはじめ、木の温もりに包まれる空間で、是非ごゆっくりおくつろぎ下さい。
            </p>
          </div>
        </article>
      </div>
      <Gallery />
    </Section>
  );
};

export default Concept;
