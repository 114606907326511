import * as React from 'react';
import { styled } from 'linaria/react';
import Arrow from '../assets/svg/arrow/button_white.svg';

const Div = styled.div`
  width: 280px;
  height: 74px;
  font-family: var(--font-en-serif);
  background: var(--white) center right 2em/50% url(${Arrow}) no-repeat;
  color: var(--font-dark);
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  :hover {
    color: var(--theme-blue);
  }
  cursor: pointer;
`;
const openLink = (uri: string) => {
  window.open(uri, '_self');
};

const ButtonArrowedWhite: React.FC<{ uri: string; style?: React.CSSProperties | undefined }> = ({
  uri,
  style,
  children,
}) => (
  <div style={{ background: 'var(--white)' }}>
    <Div style={{ ...style }} onClick={() => openLink(uri)} className="arrow-right-white">
      <div style={{ backgroundColor: 'var(--white)', paddingLeft: '2em' }}>{children}</div>
    </Div>
  </div>
);
export default ButtonArrowedWhite;
