import * as React from 'react';
import { styled } from 'linaria/react';
import { StaticImage } from 'gatsby-plugin-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useInView } from 'react-intersection-observer';
import { delay1, delay3, fadeIn, fadeInUp, Section, inViewConfig } from '../../templates/CommonLayout';
import Title from '../Title';
import Button from '../Button';

const Div = styled.div`
  background: var(--theme-brown);
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin: 2em auto;
  }
`;
const H2 = styled.h2`
  color: var(--theme-gold);
  font-family: var(--font-en-sans);
  font-size: 80px;
  font-weight: 100;
`;

const Space = () => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;

  const [ref1, inView1] = useInView(inViewConfig);
  const [ref2, inView2] = useInView(inViewConfig);
  const [ref3, inView3] = useInView(inViewConfig);

  return (
    <Section id="space" style={{ position: 'relative', color: 'var(--font-brown)', paddingTop: 0 }}>
      <div ref={ref1}>
        <Title label="Space" ruby="空間" className={inView1 ? fadeInUp : ''} />
      </div>
      <article style={{ marginTop: '2em' }} ref={ref2}>
        <Div style={{ padding: isSmallViewer ? '3em 0' : '5em' }} className={inView2 ? fadeInUp : ''}>
          <H2
            style={{
              alignSelf: 'flex-start',
              margin: 'auto',
              marginTop: isSmallViewer ? '-1.05em' : '-1.35em',
              marginBottom: isSmallViewer ? '0.4em' : '0.5em',
              fontSize: isSmallViewer ? '80px' : '100px',
            }}
            className={inView2 ? fadeInUp + delay1 : ''}
          >
            Private Room
          </H2>
          <h2 style={{ marginBottom: '1em' }}>完全個室</h2>
          <StaticImage
            src="../../assets/images/space/private_room.jpg"
            style={{
              marginBottom: isSmallViewer ? '1em' : 0,
              maxHeight: isSmallViewer ? '20em' : 'none',
            }}
            objectPosition="15% center"
            alt="専用出入口付きの完全個室をご用意しております。"
            className={inView3 ? fadeIn : ''}
          />
          <div style={{ display: 'flex', flexDirection: isSmallViewer ? 'column' : 'row-reverse' }}>
            <div style={{ flex: 0.4, alignSelf: isSmallViewer ? 'flex-end' : 'normal' }}>
              <StaticImage
                src="../../assets/images/space/interior.jpg"
                alt="桜の一枚板のカウンター、木のぬくもりを感じられる落ち着いた空間"
                objectPosition="center 15%"
                style={{ position: 'relative', maxHeight: isSmallViewer ? '20em' : 'none' }}
                className={inView3 ? fadeInUp + delay1 : ''}
              />
            </div>
            <div ref={ref3} style={{ flex: 0.6, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <p
                style={{ padding: isSmallViewer ? '0 2em' : '0 1em 0 0' }}
                className={inView3 ? fadeInUp + delay3 : ''}
              >
                桜の一枚板のカウンター、木のぬくもりを感じられる落ち着いた空間でごゆっくりおくつろぎ下さい。
                <br />
                また、専用の出入口も完備した完全個室もご用意しております。プライベートな特別な機会にも是非ご利用下さい。
                <br />
                (〜6名様まで 個室料： ¥10,000)
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                  bottom: isSmallViewer ? '-5em' : '-7em',
                  marginTop: '-5em',
                }}
              >
                <Button
                  uri="mailto:foret.riziere.tokyo@gmail.com"
                  borderColor="var(--theme-dark)"
                  style={{ color: 'var(--font-black)' }}
                  className={inView3 ? fadeInUp + delay3 : ''}
                >
                  個室ご利用お問い合わせ
                </Button>
              </div>
            </div>
          </div>
        </Div>
      </article>
    </Section>
  );
};

export default Space;
