import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useInView } from 'react-intersection-observer';
import { fadeIn, fadeInUp, Section, inViewConfig, delay1, delay2, delay3 } from '../../templates/CommonLayout';
import Title from '../Title';
import ButtonArrowedDark from '../ButtonArrowedDark';
import Logo from '../../assets/svg/logo/ec.imp.svg';
import { URL } from '../../Utils';
import Illust from '../../assets/images/ec/illust.png';

const Store = () => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;

  const [ref1, inView1] = useInView(inViewConfig);
  const [ref2, inView2] = useInView(inViewConfig);

  return (
    <div ref={ref1}>
      <Section id="store" style={{ background: 'var(--white)', marginLeft: 0, marginBottom: 0, position: 'relative' }}>
        <Title
          label="Online Store"
          ruby="オンラインストア"
          style={{
            position: isSmallViewer ? 'relative' : 'absolute',
            left: isSmallViewer ? 0 : '8%',
            top: '5em',
            color: 'var(--theme-blue)',
          }}
          className={inView1 ? fadeInUp : ''}
        />
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: isSmallViewer ? '8em' : '10em',
          }}
          className={inView1 ? fadeIn + delay1 : ''}
        >
          <Logo title="Boucherie Tokyo" style={{ width: '160px' }} />
          <p style={{ color: 'var(--font-brown)', fontSize: 16, margin: '0.5em auto 5em auto' }}>
            Boucherie Tokyo ブーシェリー・トーキョー
          </p>
        </div>
        <article style={{ display: 'flex', flexDirection: isSmallViewer ? 'column-reverse' : 'row' }} ref={ref2}>
          <div
            style={{
              flex: 0.5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
              background: `top 50% center/70% url(${Illust}) no-repeat`,
              backgroundColor: 'rgba(255, 255, 255, 0.85)',
              backgroundBlendMode: 'lighten',
              padding: '0 3em 3em 3em',
            }}
            className={inView2 ? fadeIn + delay2 : ''}
          >
            <p style={{ marginBottom: '2em' }} className={inView2 ? fadeInUp + delay3 : ''}>
              コンセプトは、 【パリの街角のお肉屋さん】
              <br />
              ヨーロッパならではの食文化である【シャルキュトリー】を、もっと気軽にワインとともにシーンを選ばず楽しんで欲しい。そんな想いから始まりました。
              <br />
              フランス料理のシェフ達が一つ一つ丁寧に仕込む【無添加】【低添加】の加工食品です。
              <br />
              シャルキュトリー各種をはじめ、【おうちレストランシリーズ】と題して、簡単にシェフの味が楽しめるよう工夫した料理も。
              <br />
              ソムリエの選ぶ楽しいワインも同時にお買い求めいただけます。
              <br />
              ぜひお気軽にご利用下さい。
            </p>
            <ButtonArrowedDark uri={URL.ec} className={inView2 ? fadeInUp + delay3 : ''}>
              Boucherie Tokyo
            </ButtonArrowedDark>
          </div>
          <div style={{ flex: 0.5, position: 'relative', marginBottom: isSmallViewer ? '3em' : 'auto' }}>
            <StaticImage
              src="../../assets/images/ec/concept.png"
              layout="fullWidth"
              objectPosition={isSmallViewer ? '30% 60%' : '30% center'}
              style={{
                position: 'relative',
                right: 0,
                minHeight: isSmallViewer ? 'none' : '500px',
                maxHeight: isSmallViewer ? '20em' : 'none',
              }}
              alt="Boucherie Tokyo ブーシェリー・トーキョー"
              className={inView2 ? fadeIn + delay2 : ''}
            />
          </div>
        </article>
      </Section>
    </div>
  );
};

export default Store;
