import * as React from 'react';
import { Link } from 'gatsby';
import { styled } from 'linaria/react';
import Button from './Button';
import EngButton from './EngButton';

const NewsItem = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  margin: 0 26px;
  :hover {
    * {
      color: var(--theme-gold);
      border-bottom: 1px solid var(--theme-gold);
    }
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Header = ({ date, title }: { date: string; title: string }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-end',
      fontFamily: 'var(--font-jp-sans)',
      fontWeight: 300,
      maxWidth: '100%',
      overflow: 'hidden',
    }}
  >
    <header style={{ position: 'relative', height: '60px', minWidth: '700px', display: 'flex' }}>
      <EngButton />
      <dl style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
        <dt
          style={{
            width: '120px',
            background: 'var(--theme-dark)',
            color: 'var(--white)',
            height: '100%',
            lineHeight: '60px',
            textAlign: 'center',
          }}
        >
          NEWS
        </dt>
        <NewsItem>
          <dd id="news-date" style={{ fontSize: '14px', marginRight: '26px' }}>
            {date}
          </dd>
          <dd id="news-date" style={{ fontSize: '14px' }}>
            {title}
          </dd>
          <Link to="/news/0" />
        </NewsItem>
      </dl>
    </header>
  </div>
);

export default Header;
