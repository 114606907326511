import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { styled } from 'linaria/react';

const Indicator: React.FC<{ className: string }> = ({ className }) => (
  <div
    style={{
      width: '3em',
      height: '0.5em',
      margin: 'auto 0.1em',
      background: 'var(--white)',
    }}
    className={className}
  />
);

const Div = styled.div`
  position: relative;
  margin-bottom: 1.45rem;
`;

const Facade = () => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;

  return (
    <Div>
      <StaticImage
        src="../assets/images/cover/03.png"
        layout="fullWidth"
        style={{ position: 'absolute', top: 0, width: '100%' }}
        alt="神保町 五木田"
        className="facade3"
      />
      <StaticImage
        src="../assets/images/cover/02.png"
        layout="fullWidth"
        style={{ position: 'absolute', top: 0, width: '100%' }}
        alt="神保町 五木田"
        className="facade2"
      />
      <StaticImage
        src="../assets/images/cover/01.png"
        layout="fullWidth"
        style={{ position: 'relative', width: '100%' }}
        alt="神保町 五木田"
        className="facade1"
      />
      <h2
        style={{
          position: 'absolute',
          bottom: isSmallViewer ? '2.5em' : '20%',
          left: isSmallViewer ? '1em' : '5%',
          fontSize: isSmallViewer ? '26px' : '40px',
          color: 'var(--white)',
          textShadow: '0px 3px 9px #000',
          lineHeight: '1.2em',
        }}
      >
        炭/薪焼きと
        <br />
        フランス料理の
        <br />
        新たなマリアージュ
      </h2>
      <div style={{ display: 'flex', position: 'absolute', bottom: '2em', left: isSmallViewer ? '2em' : '5%' }}>
        <Indicator className="facadeIndicator1" />
        <Indicator className="facadeIndicator2" />
        <Indicator className="facadeIndicator3" />
      </div>
    </Div>
  );
};

export default Facade;
