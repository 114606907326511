import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import CommonLayout from '../templates/CommonLayout';
import Header from '../components/Header';
import Facade from '../components/Facade';
import Concept from '../components/contents/Concept';
import Team from '../components/contents/Team';
import Menu from '../components/contents/Menu';
import Space from '../components/contents/Space';
import Reservation from '../components/contents/Reservation';
import Store from '../components/contents/Store';
import Info from '../components/contents/Info';
import News from '../components/contents/News';
import { formatDate } from '../Utils';

const IndexPage: React.FC<PageProps<GatsbyTypes.Query>> = ({ data }) => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;

  const news = data.allMicrocmsNews.edges.map(item => item.node);
  const latestNews = news[0];

  const menu = data.microcmsMenu;

  return (
    <CommonLayout title="神保町 五木田">
      {!isSmallViewer && latestNews && (
        <Header date={formatDate(latestNews.publishedAt) || ''} title={latestNews.title || ''} />
      )}
      <Facade />
      <News items={news} />
      <Concept />
      <Team />
      <Menu urls={menu} />
      <Space />
      <Reservation />
      <Store />
      <Info />
    </CommonLayout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    microcmsMenu {
      dinner
      wine
      drink
    }
    allMicrocmsNews(limit: 2, skip: 0, sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          publishedAt
          title
        }
      }
    }
  }
`;
