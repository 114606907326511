import * as React from 'react';
import { styled } from 'linaria/react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useInView } from 'react-intersection-observer';
import {
  fadeIn,
  fadeInUp,
  Section as SectionComponent,
  inViewConfig,
  delay1,
  delay2,
} from '../../templates/CommonLayout';
import Title from '../Title';
import Logo from '../../assets/svg/logo/info.imp.svg';
import FB from '../../assets/svg/sns/fb.svg';
import Button from '../Button';
import { URL } from '../../Utils';
import Insta from '../../assets/images/sns/insta.png';

const Section = styled(SectionComponent)`
  margin-left: 0;
  margin-bottom: 4em;
  padding: 0 5em 0 5em;
  dt,
  dd {
    line-height: 2em;
    color: var(--font-brown);
  }
  dt {
    font-weight: bold;
  }
  dd {
    font-weight: lighter;
  }
  #details dl {
    border-bottom: 1px solid var(--theme-gold);
  }
  #info-sns > div {
    border: 1px solid var(--theme-gold) !important;
    background-color: #faf8f8 !important;
    color: var(--theme-gold);

    :hover {
      color: var(--theme-blue);
    }
    margin: 1em 2em;
  }
`;

const Item: React.FC<{ title?: string }> = ({ title, children }) => (
  <dl style={{ display: 'flex', padding: '2em 0' }}>
    {title && <dt style={{ width: '120px' }}>{title}</dt>}
    <dd style={{ flex: 1 }}>{children}</dd>
  </dl>
);

const Access = () => (
  <dl style={{ marginTop: '4em' }}>
    <dt>アクセス</dt>
    <dd>東京メトロ半蔵門線 神保町駅 A2出口 徒歩4分</dd>
    <dd>JR中央/総武線 水道橋駅 西口 徒歩6分</dd>
    <dd style={{ marginTop: '2em' }}>＊専用駐車場はございません。</dd>
  </dl>
);

const Info = () => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;

  const [ref1, inView1] = useInView(inViewConfig);
  const [ref2, inView2] = useInView(inViewConfig);
  const [ref3, inView3] = useInView(inViewConfig);
  const [ref4, inView4] = useInView(inViewConfig);

  return (
    <div ref={ref1}>
      <Section id="info" style={{ marginTop: isSmallViewer ? '4em' : '8em' }}>
        <Title label="Shop Info" ruby="店舗情報" align="left" className={inView1 ? fadeInUp : ''} />
        <div
          style={{ display: 'flex', flexDirection: isSmallViewer ? 'column' : 'row', justifyContent: 'space-around' }}
          ref={ref2}
        >
          <article style={{ flex: 0.3, margin: '4em auto' }} className={inView2 ? fadeIn : ''}>
            <Logo
              title="神保町 五木田"
              style={{
                marginLeft: '-2em',
                maxWidth: '300px',
                marginRight: '3em',
                width: isSmallViewer ? '100%' : 'auto',
              }}
            />
            {!isSmallViewer && <Access />}
          </article>
          <article id="details" style={{ flex: 0.6 }} ref={ref3}>
            <div className={inView3 ? fadeInUp + delay1 : ''}>
              <Item title="住所">
                東京都千代田区西神田2-4-9
                <br />
                第二錦水ビル1F
              </Item>
              <Item title="電話">
                <a style={{ color: 'var(--font-brown)' }} href="tel:03-6272-4365">
                  03-6272-4365
                </a>
              </Item>
              <Item title="店休日">
                月曜、月2回日曜
                <br />
                (不定休、祝前日等変更あり)
              </Item>
              <Item title="営業時間">17:30 - 23:30</Item>
              <Item title="ドレスコード">
                スマートカジュアルとさせて頂いております。
                ビーチサンダル、短パンなど明らかな軽装は入店をお断りする場合がございます。
              </Item>
            </div>
          </article>
        </div>
        <div ref={ref4}>
          <div
            id="info-sns"
            style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '2em' }}
            className={inView4 ? fadeInUp + delay2 : ''}
          >
            <Button
              uri={URL.instagram}
              borderColor="var(--theme-gold)"
              style={{
                background: `left 5em center/20px url(${Insta}) no-repeat`,
              }}
            >
              <p>Instagram</p>
            </Button>
            <Button
              uri={URL.facebook}
              borderColor="var(--theme-gold)"
              style={{
                background: `left 5em center/20px url(${FB}) no-repeat`,
              }}
            >
              <p>Facebook</p>
            </Button>
          </div>
        </div>
        {isSmallViewer && <Access />}
      </Section>
      <div id="map">
        <iframe
          title="google map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12960.53414281202!2d139.7542484!3d35.6983314!3m2!1i1024!2i768!4f5!3m3!1m2!1s0x0%3A0x2f6429e0aab323b!2z56We5L-d55S6IOS6lOacqOeUsCBKaW5ib2NobyBHb2tpdGE!5e0!3m2!1sja!2sjp!4v1643018892323!5m2!1sja!2sjp"
          style={{ border: 'none', width: '100%', height: '500px' }}
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default Info;
