import * as React from 'react';
import { Link } from 'gatsby';
import { styled } from 'linaria/lib/react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useInView } from 'react-intersection-observer';
import { delay1, fadeInUp, Section, inViewConfig } from '../../templates/CommonLayout';
import Title from '../Title';
import ButtonArrowedWhite from '../ButtonArrowedWhite';
import { formatDate } from '../../Utils';

const NewsItemWrapper = styled.div`
  position: relative;
  box-sizing: content-box;
  padding: 12px 0px;
  border-bottom: 1px solid var(--theme-gray);
  :hover {
    border-bottom: 1px solid var(--theme-gold);
    * {
      color: var(--theme-gold);
    }
  }
  a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const NewsItem = ({ date, summary, index }: { date: string; summary: string; index: number }) => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;
  return (
    <NewsItemWrapper>
      <dl
        style={{
          position: 'relative',
          display: 'flex',
          // flexWrap: 'wrap',
          color: 'var(--font-brown)',
          maxWidth: isSmallViewer ? '450px' : '700px',
        }}
      >
        <dt style={{ fontFamily: 'var(--font-jp-sans)', fontSize: '14px', verticalAlign: 'middle' }}>{date}</dt>
        <dd style={{ fontFamily: 'var(--font-jp-serif)', marginLeft: '35px', verticalAlign: 'middle' }}>{summary}</dd>
      </dl>
      <Link to={`/news/${index}`} />
    </NewsItemWrapper>
  );
};

const News = ({ items }: { items: GatsbyTypes.MicrocmsNews[] }) => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;

  const [ref1, inView1] = useInView(inViewConfig);
  const [ref2, inView2] = useInView(inViewConfig);

  return (
    <Section style={{ paddingTop: '3em', paddingLeft: 0 }}>
      <div ref={ref1}>
        <Title label="News" ruby="お知らせ" className={inView1 ? fadeInUp : ''} />
      </div>
      <div ref={ref2}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: isSmallViewer ? '1em' : '4em',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
          className={inView2 ? fadeInUp + delay1 : ''}
        >
          <div style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
            {items.map((item, index) => (
              <NewsItem
                key={item.id + item.publishedAt}
                index={index}
                date={formatDate(item.publishedAt) || '最新ニュース'}
                summary={item.title || '最新ニュース'}
              />
            ))}
          </div>
          <ButtonArrowedWhite uri="/newslist">read more</ButtonArrowedWhite>
        </div>
      </div>
    </Section>
  );
};

export default News;
