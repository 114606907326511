import * as React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Title = ({
  label,
  ruby,
  style,
  className,
  align = 'center',
}: {
  label: string;
  ruby: string;
  style?: React.CSSProperties | undefined;
  className?: string;
  align?: 'center' | 'left';
}) => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;
  return (
    <div
      style={{
        color: 'var(--theme-gold)',
        textAlign: isSmallViewer ? 'center' : align,
        ...style,
      }}
      className={className}
    >
      <h1 className="title">{label}</h1>
      <p style={{ marginTop: '0.5em' }}>{ruby}</p>
    </div>
  );
};

export default Title;
