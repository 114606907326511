import * as React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';
import { delay1, fadeIn, fadeInUp, Section, inViewConfig } from '../../templates/CommonLayout';
import Title from '../Title';

const Team = () => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;

  const [ref1, inView1] = useInView(inViewConfig);
  const [ref2, inView2] = useInView(inViewConfig);
  const [ref3, inView3] = useInView(inViewConfig);
  const [ref4, inView4] = useInView(inViewConfig);
  const [ref5, inView5] = useInView(inViewConfig);
  const [ref6, inView6] = useInView(inViewConfig);

  return (
    <Section id="team" style={{ paddingRight: !isSmallViewer ? '4em' : 0 }}>
      <div ref={ref1}>
        <Title label="Our Team" ruby="私たちについて" align="left" className={inView1 ? fadeInUp : ''} />
      </div>
      <article
        style={{
          display: 'flex',
          flexDirection: isSmallViewer ? 'column' : 'row-reverse',
          alignItems: 'center',
          margin: '2em auto',
        }}
      >
        <div style={{ flex: !isSmallViewer ? 0.7 : 'auto' }} ref={ref2}>
          <StaticImage
            src="../../assets/images/team/01.png"
            objectPosition="80% center"
            style={{
              minHeight: isSmallViewer ? 'none' : '500px',
              width: isSmallViewer ? '100%' : 'auto',
            }}
            alt="私たちはスタッフ全員で高い意識でコラボレーションし、料理の楽しさとともにお食事をご提供します。"
            className={inView2 ? fadeIn : ''}
          />
        </div>
        <div
          ref={ref3}
          style={{
            display: 'flex',
            flex: !isSmallViewer ? 0.3 : 'auto',
            marginTop: isSmallViewer ? '4em' : '0em',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p
            style={{ writingMode: 'vertical-rl', letterSpacing: '0.6em' }}
            className={inView3 ? fadeInUp + delay1 : ''}
          >
            店で過ごすひとときを
            <br />
            存分に楽しんで頂ける事。
            <br />
            それが私たちの喜びです。
            <br />
            常にその想いで
            <br />
            お食事をご提供できるように
            <br />
            スタッフ全員で進化を続けます。
            <br />
            <span style={{ display: 'block', fontSize: '20px', marginRight: '1.5em' }}>心を込めたお料理を。</span>
          </p>
        </div>
      </article>

      <article
        style={{
          display: 'flex',
          flexDirection: isSmallViewer ? 'column' : 'row',
          alignItems: 'center',
          padding: isSmallViewer ? '0 4em' : 0,
        }}
      >
        <div style={{ flex: 0.5, marginBottom: isSmallViewer ? '2em' : 'auto' }} ref={ref4}>
          <StaticImage
            src="../../assets/images/team/02.png"
            alt="神保町 五木田 チームメンバー"
            className={inView4 ? fadeIn : ''}
          />
        </div>
        <div ref={ref5} style={{ flex: 0.5, marginLeft: isSmallViewer ? 'auto' : '4em' }}>
          <p className={inView5 ? fadeIn + delay1 : ''}>
            私たちの目指す料理は、決して一人ではできないもの。
            <br />
            チームでやるからこそ出来るものがあります。
            <br />
            私たち一人ひとりがクリエイターであり、それぞれの個性を活かし、学び合い、そして分かち合う。
            <br />
            そんな想いで、常にお互いを刺激し合いながら進化を続けるチーム。それが私たちです。
            <br />
            私たちのコラボレーションで生まれるお料理を、ぜひ美味しいお酒とともに、心ゆくまでお楽しみください。
          </p>
        </div>
      </article>

      <article
        id="chef"
        style={{
          padding: isSmallViewer ? '0 3em' : '3em 0',
          marginTop: isSmallViewer ? '4em' : '4em',
        }}
        ref={ref6}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: isSmallViewer ? 'column-reverse' : 'row',
            color: 'var(--theme-gold)',
            borderColor: 'var(--theme-gold)',
            borderTop: '1px solid',
            borderBottom: '1px solid',
            padding: isSmallViewer ? '4em 0' : '2em',
          }}
          className={inView6 ? fadeInUp : ''}
        >
          <div
            style={{
              marginRight: isSmallViewer ? 0 : '3em',
            }}
          >
            <div
              style={{
                fontFamily: 'var(--font-en-serif)',
                display: 'flex',
                alignItems: 'flex-end',
                marginBottom: '2em',
                justifyContent: isSmallViewer ? 'center' : 'normal',
              }}
            >
              <h2 style={{ fontSize: '25px', marginRight: '0.8em' }}> Owner chef</h2>
              <div>
                <p
                  style={{
                    fontSize: '16px',
                    lineHeight: '1em',
                    letterSpacing: '0.35em',
                  }}
                >
                  Yuto Gokita
                </p>
                <p
                  style={{
                    fontSize: '30px',
                    fontFamily: 'var(--font-jp-serif)',
                    fontWeight: 'bold',
                    lineHeight: '1em',
                  }}
                >
                  <span style={{ whiteSpace: 'nowrap' }}>五木田</span>{' '}
                  <span style={{ whiteSpace: 'nowrap' }}>祐人</span>
                </p>
              </div>
            </div>
            <p style={{ fontSize: '16px', color: 'var(--font-dark)' }}>
              1985年、茨城県生まれ。20歳より「銀座レストラン ペリニョン」青木健晃氏のもとフランス料理の世界へ。
              25歳で渡仏し、南フランスの二つ星「ラ・レゼルヴ・ドボリュー」、ブルゴーニュの一つ星「ル・ジャルダン・デ・ランパール」、「オステルリー・ヴュー・ムーラン」などのレストランを経て帰国。
              赤坂【シュマン】でスーシェフを務め、恵比寿【セリエ】、青山【マノワールディノ】でシェフとして活躍。
              また本場フランスやオーナーソムリエなどの元で培ったワインへの見識も豊富。
              <br />
              2021年7月に【神保町
              五木田】、オンラインシャルキュトリーストア【ブーシェリー・トーキョー】を同時にオープン。
              <br />
              フランスと日本の文化の融合を表現した料理「イノベーティブ・ジャパニーズ」をテーマに新しいスタイルの料理で多くの顧客を魅了している。
            </p>
          </div>
          <StaticImage
            src="../../assets/images/team/chef.png"
            style={{ minWidth: '200px', minHeight: '200px', marginBottom: isSmallViewer ? '3em' : '0' }}
            layout="fullWidth"
            quality={100}
            alt="オーナーシェフ 五木田 祐人"
          />
        </div>
      </article>
    </Section>
  );
};

export default Team;
