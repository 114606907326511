import * as React from 'react';
import { styled } from 'linaria/react';

const Div = styled.div`
  width: 280px;
  height: 74px;
  font-family: var(--font-en-serif);
  background-color: var(--white);
  color: var(--font-dark);
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  :hover {
    color: var(--theme-blue);
  }
  /* cursor: pointer; */

  a {
    position: absolute;
    margin-top: 1px;
  }
  svg rect,
  svg path,
  svg polyline {
    fill: none;
    stroke-width: 1;
  }
  :hover svg rect {
  }

  svg rect {
    stroke-dasharray: 400, 0;
    transition: all 0.4s ease-in-out;
    /* -webkit-transition: all 0.8s ease-in-out; */
    /* -moz-transition: all 0.8s ease-in-out;
    -ms-transition: all 0.8s ease-in-out;
    -o-transition: all 0.8s ease-in-out; */
  }
  :hover svg rect {
    stroke-width: 3;
    stroke-dasharray: 120, 240;
    stroke-dashoffset: 38;
    transition: all 0.4s ease-in-out;
    /* -webkit-transition: all 0.8s ease-in-out;
    -moz-transition: all 0.8s ease-in-out;
    -ms-transition: all 0.8s ease-in-out;
    -o-transition: all 0.8s ease-in-out; */
  }
`;

const Button: React.FC<{
  uri: string;
  bgColor?: string;
  borderColor?: string;
  blank?: boolean;
  style?: React.CSSProperties | undefined;
  className?: string;
}> = ({ uri, bgColor, borderColor, blank = true, style, className, children }) => (
  <div style={{ background: bgColor }} className={className}>
    <Div
      style={{
        background: 'var(--white)',
        justifyContent: 'center',
        ...style,
      }}
    >
      <div style={{ backgroundColor: bgColor }}>{children}</div>
      <a href={uri} target={blank ? '_blank' : '_self'} rel="noopener noreferrer">
        <svg width="280" height="74" viewBox="0 0 280 74" xmlns="http://www.w3.org/2000/svg">
          <rect x="0" y="0" fill="none" stroke={borderColor} width="280" height="74" />
        </svg>
      </a>
    </Div>
  </div>
);
export default Button;
