import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import { URL } from '../../Utils';
import { fadeInUp, Section, inViewConfig } from '../../templates/CommonLayout';
import Button from '../Button';
import Title from '../Title';

const Reservation = () => {
  const [ref1, inView1] = useInView(inViewConfig);
  return (
    <div ref={ref1}>
      <Section
        id="reservation"
        style={{
          background: 'var(--theme-brown)',
          display: 'flex',
          flexWrap: 'wrap',
          marginLeft: 0,
          padding: '5%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className={inView1 ? fadeInUp : ''}
      >
        <Title
          label="Reservation"
          ruby="ご予約"
          style={{ color: 'var(--font-dark)', marginRight: '4%', marginBottom: '4%' }}
        />
        <div style={{ flex: 1 }}>
          <div
            style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '-2%', marginLeft: '-2%' }}
          >
            <Button
              uri={URL.reserve}
              borderColor="var(--white)"
              style={{ background: 'var(--theme-dark)', margin: '2%', color: 'var(--white)' }}
            >
              Web予約はこちら
            </Button>
            <Button
              uri="tel:03-6272-4365"
              borderColor="var(--theme-dark)"
              style={{
                display: 'flex',
                flexDirection: 'column',
                background: 'var(--white)',
                margin: '2%',
              }}
              blank={false}
            >
              <p style={{ lineHeight: 1.5 }}>03-6272-4365</p>
              <p style={{ lineHeight: 1.5, fontSize: 11, fontWeight: 200 }}>月曜/月2回日曜店休(不定休あり)</p>
            </Button>
          </div>
          <p style={{ color: 'var(--font-light)', marginTop: '1em', textAlign: 'center' }}>
            お問い合わせ:{' '}
            <a
              style={{ marginLeft: '1em', color: 'var(--font-light)', textDecoration: 'underline' }}
              href="mailto:foret.riziere.tokyo@gmail.com"
            >
              foret.riziere.tokyo@gmail.com
            </a>
          </p>
        </div>
      </Section>
    </div>
  );
};

export default Reservation;
