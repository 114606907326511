import * as React from 'react';
import { styled } from 'linaria/react';
import { StaticImage } from 'gatsby-plugin-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useInView } from 'react-intersection-observer';
import { delay1, delay2, delay3, delay4, fadeIn, fadeInUp, Section, inViewConfig } from '../../templates/CommonLayout';
import Title from '../Title';
import Button from '../Button';

const Div = styled.div`
  background: var(--theme-brown);
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin: 2em auto;
  }
`;
const H2 = styled.h2`
  color: var(--theme-gold);
  font-family: var(--font-en-sans);
  font-size: 100px;
  font-weight: 100;
`;

const Menu = ({ urls }: { urls?: GatsbyTypes.MicrocmsMenu }) => {
  const device = useBreakpoint();
  const isInXLViewer = device.l;
  const isSmallViewer = device.m;
  const isXSViewer = device.s;

  const [ref1, inView1] = useInView(inViewConfig);
  const [ref2, inView2] = useInView(inViewConfig);
  const [ref3, inView3] = useInView(inViewConfig);
  const [ref4, inView4] = useInView(inViewConfig);

  const marginBottomL = isInXLViewer ? '20em' : '25em';

  return (
    <Section id="menu" style={{ position: 'relative', color: 'var(--font-brown)', marginBottom: 0 }}>
      <div ref={ref1}>
        <Title label="Menu" ruby="お品書き" className={inView1 ? fadeInUp : ''} />
      </div>
      <article style={{ position: 'relative', marginBottom: isXSViewer ? '10em' : marginBottomL }}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: isSmallViewer ? 'column-reverse' : 'row',
            alignItems: 'center',
            marginTop: '2em',
          }}
          ref={ref2}
        >
          <Div style={{ padding: isSmallViewer ? '3em' : '5em' }} className={inView2 ? fadeInUp : ''}>
            <H2
              style={{
                position: isSmallViewer ? 'relative' : 'absolute',
                top: isSmallViewer ? '0em' : '-0.6em',
                alignSelf: 'flex-start',
                fontSize: isSmallViewer ? '80px' : '100px',
              }}
              className={inView2 ? fadeInUp + delay3 : ''}
            >
              Dinner
            </H2>
            <p className={inView2 ? fadeInUp + delay4 : ''}>
              火入れの難しい炭焼き、薪焼きを駆使したお料理と、串料理、
              本格フランス料理のコラボレーションをお楽しみ下さい。
            </p>
            <Button
              className={inView2 ? fadeInUp + delay4 : ''}
              borderColor="var(--theme-dark)"
              uri={urls?.dinner ?? '/tbd'}
              style={{ color: 'var(--font-black)' }}
            >
              Dinner Menu
            </Button>
            <StaticImage
              src="../../assets/images/menu/dinner/left.png"
              style={{ width: '40%', position: 'absolute', top: '80%', left: '2em' }}
              alt="Entree: 前菜は鮮やかな驚きや楽しさとともに。"
              className={inView2 ? fadeInUp + delay1 : ''}
            />
          </Div>
          <div>
            <StaticImage
              src="../../assets/images/menu/dinner/right.png"
              style={{
                minHeight: isSmallViewer ? 'none' : '580px',
                maxWidth: '600px',
                maxHeight: isSmallViewer ? '400px' : 'none',
              }}
              objectPosition={isSmallViewer ? '30% 60%' : '30% center'}
              alt="Kushi: フレンチの技法を応用した薪火焼きの串料理。"
              className={inView2 ? fadeIn : ''}
            />
          </div>
        </div>
        {/* <div
          style={{
            position: 'relative',
            marginTop: isSmallViewer ? '-29em' : '-5%',
            marginBottom: isSmallViewer ? '34em' : 'auto',
            display: 'flex',
            justifyContent: isSmallViewer ? 'flex-end' : 'center',
          }}
        >
          <StaticImage
            src="../../assets/images/menu/dinner/center.png"
            style={{ maxWidth: '50%', width: isSmallViewer ? '46%' : '45%' }}
            alt="Viande: メインはクラシカルフレンチで培われた料理を。"
            className={inView2 ? fadeInUp + delay2 : ''}
          />
        </div> */}
      </article>

      <article style={{ marginTop: '8em' }} ref={ref3}>
        <Div style={{ padding: isSmallViewer ? '3em 0' : '5em' }} className={inView3 ? fadeInUp : ''}>
          <H2
            style={{
              alignSelf: 'flex-start',
              marginTop: isSmallViewer ? '-1.05em' : '-1.35em',
              marginBottom: isSmallViewer ? '0.4em' : '0.5em',
              marginLeft: isSmallViewer ? '0.45em' : 0,
              fontSize: isSmallViewer ? '80px' : '100px',
            }}
            className={inView3 ? fadeInUp + delay2 : ''}
          >
            Wines
          </H2>
          <div style={{ display: 'flex', flexDirection: isSmallViewer ? 'column' : 'row' }}>
            <StaticImage
              src="../../assets/images/menu/wine/01.png"
              style={{
                flex: 0.6,
                marginRight: isSmallViewer ? 0 : '10%',
                marginBottom: isSmallViewer ? '1em' : 0,
                maxHeight: isSmallViewer ? '20em' : 'none',
              }}
              objectPosition="15% center"
              alt="グラスワインも常時豊富に取り揃えております。"
              className={inView3 ? fadeIn : ''}
            />
            <div style={{ flex: 0.4, alignSelf: isSmallViewer ? 'flex-end' : 'normal' }}>
              <StaticImage
                src="../../assets/images/menu/wine/02.png"
                alt="ブルゴーニュを中心に各種ご用意しております。ぜひお食事とともにお楽しみ下さい。"
                objectPosition="center 15%"
                style={{ position: 'relative', top: '-35%', maxHeight: isSmallViewer ? '20em' : 'none' }}
                className={inView3 ? fadeInUp + delay1 : ''}
              />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <p style={{ padding: isSmallViewer ? '0 2em' : 0 }} className={inView3 ? fadeInUp + delay3 : ''}>
                  オーナーソムリエの元での修行時代やフランス各地での経験を反映したワインたち。
                  <br />
                  ブルゴーニュを中心に日本ワインにも特化しており、ボトルだけでなくグラスワインも常時豊富に取り揃えております。
                  <br />
                  ぜひお食事とともにお楽しみ下さい。
                </p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                    bottom: isSmallViewer ? '-5em' : '-7em',
                    marginTop: '-5em',
                  }}
                >
                  <Button
                    uri="/winelist"
                    borderColor="var(--theme-dark)"
                    style={{ color: 'var(--font-black)' }}
                    className={inView3 ? fadeInUp + delay3 : ''}
                  >
                    Wine List
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Div>
      </article>

      <article style={{ marginTop: isSmallViewer ? '25em' : '20%', position: 'relative' }} ref={ref4}>
        <Div
          style={{ padding: isSmallViewer ? '2em' : '5em', position: 'relative' }}
          className={inView4 ? fadeInUp : ''}
        >
          <H2
            style={{ alignSelf: isSmallViewer ? 'flex-start' : 'flex-end', fontSize: isSmallViewer ? '80px' : '100px' }}
            className={inView4 ? fadeInUp + delay2 : ''}
          >
            Drinks
          </H2>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                position: isSmallViewer ? 'absolute' : 'relative',
                left: 0,
                flex: 0.65,
                marginTop: isSmallViewer ? '-26em' : '-30%',
                width: '100%',
              }}
            >
              <StaticImage
                src="../../assets/images/menu/drinks/01.png"
                style={{
                  opacity: 0,
                  minWidth: '300px',
                  width: isSmallViewer ? '100%' : 'auto',
                  maxHeight: isSmallViewer ? '20em' : 'none',
                }}
                alt="日本茶、その他ノンアルコールドリンクも充実しております。"
                className={inView4 ? fadeIn : ''}
              />
              <div style={{ position: 'relative', top: '-10%' }}>
                {inView4 && (
                  <StaticImage
                    src="../../assets/images/menu/drinks/02.png"
                    style={{
                      position: 'absolute',
                      width: isSmallViewer ? '100%' : '60%',
                      right: isSmallViewer ? 0 : '-15%',
                      maxHeight: isSmallViewer ? '12em' : 'none',
                      maxWidth: isSmallViewer ? '45%' : 'none',
                    }}
                    alt="各種食前酒、食後酒も取り揃えております。"
                    className={inView4 ? fadeInUp + delay1 : ''}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                flex: isSmallViewer ? 1 : 0.35,
                marginLeft: isSmallViewer ? 0 : '10%',
                marginTop: isSmallViewer ? '3em' : 0,
              }}
              className={inView4 ? fadeInUp + delay3 : ''}
            >
              <p>
                旨み高い日本茶である八女茶や、それを使用したオリジナルカクテルもご用意しております。
                <br />
                また各種食後酒や、マイスター資格も取得しておりますシードルを樽からなどもお楽しみ頂けます。
              </p>
            </div>
          </div>
        </Div>
        <div
          style={{
            position: 'relative',
            bottom: '3em',
            right: isSmallViewer ? 0 : '4em',
            display: 'flex',
            justifyContent: isSmallViewer ? 'center' : 'flex-end',
          }}
          className={inView4 ? fadeInUp + delay3 : ''}
        >
          <Button uri={urls?.drink ?? '/tbd'} borderColor="var(--theme-dark)" style={{ color: 'var(--font-black)' }}>
            Drink Menu
          </Button>
        </div>
      </article>
    </Section>
  );
};

export default Menu;
